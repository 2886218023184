export enum AuthUrl {
  GET_STARTED = 'get-started',
  SIGNUP = 'register',
  LOGIN = 'login',
  EMAIL_LOGIN = 'login/email',
  EMAIL_SIGNUP = 'register/email',
  FORGOT_PASSWORD = 'forgot-password',

  // conversational-OB-improvement
  SIGNUP_PASSWORD_VARIANT = 'signup/password',
  SIGNUP_EMAIL_VARIANT = 'signup/email',
  LOGIN_VARIANT = 'login-variant',
}
